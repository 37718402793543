import "../App.css";
import Home from "./home.js";
import Service from "./services.js";
import About from "./about.js";
import Review from "./reviews.js";
import Contact from "./contact.js";
import "../styles/css/app.css";
import { useEffect } from "react";
import Info from "./Info";

function Main() {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "instant" });
      }
    }
  }, []);

  return (
    <div>
      <section id="home"></section>
      <Home />
      <Service />
      <About />
      <Review />
      <Contact />
    </div>
  );
}

export default Main;
