import React from "react";
import "../styles/css/home.css";
import { Link } from "react-scroll";
import Navbar from "../components/navbar";

const Home = () => {
  return (
    <div class="homeWrapper">
      <Navbar isIndexx={true} />
      <div className="overlay"></div>
      <div class="content">
        <div class="title">
          <div class="strong">STRONG</div>
          <div class="discipline">DISCIPLINE</div>
        </div>
        <div class="description">Strong mind, strong body</div>
      </div>
    </div>
  );
};

export default Home;
