import React from "react";
import "../styles/css/about.css";
import picture1 from "../styles/images/SverreBicep.JPG";
import picture2 from "../styles/images/Sverrevg.jpg";

const About = () => {
  return (
    <section id="about" className="aboutContainer">
      <div className="aboutTitle">
        Over mij <span className="underline"></span>
      </div>

      <div className="aboutWrapper">
        <div className="imageWrapper">
          <img src={picture1} alt="Sverre Van Goethem" className="image" />
        </div>
        <div className="textWrapper">
          <div className="title">
            <span className="firstName">Sverre</span>
            <span className="lastName">van Goethem</span>
          </div>
          <div className="box">
            <p>
              Hoi! Mijn naam is Sverre van Goethem. Ik ben verheugd om mijn
              kennis en expertise met jou te delen en je te ondersteunen bij het
              behalen van jouw fysieke doelen. Ik geloof sterk in het belang van
              op maat gemaakte trainings- en voedingsschema&#39;s die
              wetenschappelijk zijn onderbouwd en afgestemd zijn op jouw
              specifieke behoeften en doelen. Ik ben ervan overtuigd dat deze
              gepersonaliseerde aanpak de sleutel tot succes is. Mijn benadering
              gaat echter verder dan alleen het verstrekken van schema&#39;s. Ik
              streef ernaar mijn cliënten autonomie te geven, zodat ze de kennis
              en vaardigheden kunnen ontwikkelen om zelfstandig gezondere keuzes
              te maken.
            </p>
          </div>
        </div>
      </div>

      <div className="aboutWrapper reverse">
        <div className="imageWrapper">
          <img src={picture2} alt="Sverre Van Goethem" className="image" />
        </div>
        <div className="textWrapper">
          <div className="title">
            <span className="firstName">Mijn</span>
            <span className="lastName">Verhaal</span>
          </div>
          <div className="box">
            <p>
              Mijn naam is Sverre van Goethem, 22 jaar oud en heb een passie
              voor krachttraining. Mijn motivatie ligt in het verbeteren van
              fysieke prestaties, en beschik over meerdere jaren ervaring in
              deze discipline. Ik heb diverse opleidingen gevolgd, waaronder de
              MILO Personal Trainer, voedingsleer en aansluitende opleidingen.
              Constant blijf ik mezelf bijscholen om mijn kennis en vaardigheden
              te verbeteren. Mijn fascinatie voor krachttraining begon in mijn
              schooltijd, waar het me inspireerde om altijd de sterkste versie
              van mezelf te willen zijn. Tot op de dag van vandaag blijf ik
              mezelf uitdagen en ga ik buiten mijn comfortzone om het maximale
              uit lichaam en geest te halen. Ik geloof in de impact van training
              en voeding op de transformatie van het lichaam. Door fouten te
              maken, op te staan na het vallen en veel zelfstudie heb ik alle
              stappen doorlopen, van een beginner tot aan wedstrijdniveau. Mijn
              toewijding aan sport en zelfverbetering heeft me geleerd dat
              discipline essentieel is. Niet alleen in de sportschool, maar ook
              in het dagelijks leven. Ik deel graag mijn kennis en ervaring om
              anderen te helpen hun doelen te bereiken en hun kracht te
              vergroten. Ik ben ervan overtuigd dat krachttraining niet alleen
              fysiek sterker maakt, maar ook mentaal. Het is een manier om
              jezelf te overwinnen en obstakels te doorbreken. Wat eerst iets
              onmogelijk leek, wordt het plots haalbaar. Het is mijn passie om
              mensen te begeleiden, hun grenzen te verleggen en te ontdekken hoe
              sterk ze werkelijk zijn. Ik ben Sverre van Goethem, een gedreven
              krachtsporter die zichzelf constant uitdaagt en anderen graag
              helpt sterker te worden. Voor mij zijn discipline,
              doorzettingsvermogen en mentale kracht cruciale elementen om ons
              lichaam te transformeren en onze doelen te bereiken!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
