import React, { useState } from "react";
import { useForm } from "@formspree/react";
import "../styles/css/contact.css";

const Contact = () => {
  const [state, handleSubmit] = useForm("xbjnblva");
  const [submitted, setSubmitted] = useState(false);

  const handleFormSubmit = async (event) => {
    await handleSubmit(event);
    if (state.succeeded) {
      setSubmitted(true);
      window.location.reload();
    }
  };

  if (submitted) {
    return (
      <p>Bedankt voor je bericht! We nemen zo snel mogelijk contact op.</p>
    );
  }

  return (
    <section id="contact" name="contact" className="contactWrapper">
      <form onSubmit={handleFormSubmit} className="formWrapper">
        <h2 className="title">
          Contact
          <span className="underline-contact"></span>
        </h2>
        <div className="description">
          Neem contact op zodat we snel aan de slag kunnen samen!
        </div>
        <div className="labelTextGroup">
          <label htmlFor="fname">Naam</label>
          <input type="text" id="fname" name="name" />
        </div>
        <div className="labelTextGroup">
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" />
        </div>
        <div className="labelTextGroup">
          <label htmlFor="subject">Telefoonnummer</label>
          <input type="text" id="subject" name="subject" />
        </div>
        <div className="labelTextGroup">
          <label htmlFor="message">Bericht</label>
          <textarea id="message" name="message" />
        </div>
        <div className="submit">
          <button type="submit" disabled={state.submitting}>
            Verzenden
          </button>
        </div>
      </form>
    </section>
  );
};

export default Contact;
