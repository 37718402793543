import React from "react";
import "../styles/css/reviews.css";
import Sverre from "../styles/images/Knipsel.PNG";

const Reviews = () => {
  return (
    <section class="reviewContainer" id="reviews">
      <h2 className="services-title">
        Bekijk resultaten van tevreden klanten
        <span className="underline-review"></span>
      </h2>
      <h4 className="services-description">
        Wij zijn trots op onze atleten. Of het nou gaat om afvallen, kracht
        opbouwen of vinden van balans in het leven. Samen hebben we een
        fantastisch resultaat behaald. Bekijk de verhalen en de resultaten van
        de klanten hieronder.
      </h4>
      <h1 className="comingsoon">COMING SOON!</h1>
      <div class="reviewWrapper">
        {/* <div class="review">
          <div class="reviewFotoWrapper">
            <div class="fotoLeft">
              <img src={Sverre} alt="sverreBefore" className="reviewFoto" />
            </div>
          </div>
        </div>
        <div class="review">
          <div class="reviewFotoWrapper">
            <div class="fotoLeft">
              <img src={Sverre} alt="sverreBefore" className="reviewFoto" />
            </div>
          </div>
        </div>
        <div class="review">
          <div class="reviewFotoWrapper">
            <div class="fotoLeft">
              <img src={Sverre} alt="sverreBefore" className="reviewFoto" />
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default Reviews;
