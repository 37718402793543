import React, { useState, useEffect } from "react";
import "./navbar.css";
import Logo from "../../styles/images/Logo.png";
import { Link as PageLink } from "react-router-dom";
import { Link } from "react-scroll";

const Navbar = ({ isIndexx }) => {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => setShowNavbar(false), true);
    return () => {
      window.removeEventListener("scroll", () => setShowNavbar(false), true);
    };
  }, []);

  return (
    <div className="navbarContainer">
      <div className="logoWrap">
        <a href="/">
          <img src={Logo} className="logo" alt="logo" />
        </a>
      </div>
      <div className={`navItems ${showNavbar && "active"}`}>
        {/* Placeholder for other nav items */}
        <a
          href="https://www.instagram.com/strongdiscipline/"
          className="social-icon"
        >
          <i className="fab fa-instagram"></i>
        </a>
        {/* <a href="https://twitter.com" className="social-icon">
          <i className="fab fa-twitter"></i>
        </a>
        <a href="https://tiktok.com" className="social-icon">
          <i className="fab fa-tiktok"></i>
        </a> */}
        <div className="navItem">
          {isIndexx ? (
            <Link
              to="contact"
              spy={true}
              smooth={true}
              duration={1500}
              onClick={() => setShowNavbar(false)}
              className="contact-button"
            >
              Contact
            </Link>
          ) : (
            <PageLink to="/home#contact" className="contact-button">
              Contact
            </PageLink>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
