import React from "react";
import Navbar from "../../components/navbar";
import "./reviewPage.css";
import Sverre from "../../styles/images/sverrevg_front.jpg";

const reviewPage = () => {
  return (
    <div>
      <Navbar onePage={false} />
      <div className="reviewPageTitle">Meer reviews</div>
      <div className="reviewPageWrapper">
        <div class="review">
          <div class="title">Sverre Van Goethem</div>
          <div class="reviewFotoWrapper">
            <div class="fotoLeft">
              <img src={Sverre} alt="sverreBefore" className="reviewFoto" />
            </div>
            <hr class="reviewLine" />
            <div class="fotoRight">
              <img src={Sverre} alt="sverreAfter" className="reviewFoto" />
            </div>
          </div>
          <div class="description">Mooie tekst over Sverre</div>
        </div>
        <div class="review">
          <div class="title">Sverre Van Goethem</div>
          <div class="reviewFotoWrapper">
            <div class="fotoLeft">
              <img src={Sverre} alt="sverreBefore" className="reviewFoto" />
            </div>
            <hr class="reviewLine" />
            <div class="fotoRight">
              <img src={Sverre} alt="sverreAfter" className="reviewFoto" />
            </div>
          </div>
          <div class="description">Mooie tekst over Sverre</div>
        </div>
        <div class="review">
          <div class="title">Sverre Van Goethem</div>
          <div class="reviewFotoWrapper">
            <div class="fotoLeft">
              <img src={Sverre} alt="sverreBefore" className="reviewFoto" />
            </div>
            <hr class="reviewLine" />
            <div class="fotoRight">
              <img src={Sverre} alt="sverreAfter" className="reviewFoto" />
            </div>
          </div>
          <div class="description">Mooie tekst over Sverre</div>
        </div>

        <div class="review">
          <div class="title">Sverre Van Goethem</div>
          <div class="reviewFotoWrapper">
            <div class="fotoLeft">
              <img src={Sverre} alt="sverreBefore" className="reviewFoto" />
            </div>
            <hr class="reviewLine" />
            <div class="fotoRight">
              <img src={Sverre} alt="sverreAfter" className="reviewFoto" />
            </div>
          </div>
          <div class="description">Mooie tekst over Sverre</div>
        </div>
      </div>
    </div>
  );
};

export default reviewPage;
