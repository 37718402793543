import React, { useState, useEffect } from "react";
import "../styles/css/service.css";
import logo from "../styles/images/fotolaptop.png";
import logo2 from "../styles/images/1op1.jpg";

const Service = () => {
  const [flippedCard, setFlippedCard] = useState(null);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolling(true);
      clearTimeout(window.scrollTimeout);
      window.scrollTimeout = setTimeout(() => {
        setIsScrolling(false);
      }, 200);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const flipCard = (service) => {
    if (flippedCard === service) {
      setFlippedCard(null);
    } else {
      setFlippedCard(service);
    }
  };

  return (
    <div className={`services-section ${isScrolling ? "scrolling" : ""}`}>
      <h2 className="services-title">
        Diensten
        <span className="underline"></span>
      </h2>
      <div className="services-grid">
        {["Online coaching", "1 op 1 training"].map((service) => (
          <div
            key={service}
            className={`service-card ${
              flippedCard === service ? "flipped" : ""
            }`}
            onClick={() => flipCard(service)}
          >
            <div
              className="service-image"
              style={{
                backgroundImage: `url(${
                  service === "Online coaching" ? logo : logo2
                })`,
              }}
            >
              <div className="service-overlay">
                <div className="service-info">
                  <h1>{service}</h1>
                  <button className="cta-button">Meer Info</button>
                </div>
              </div>
            </div>
            <div
              className="service-image-back"
              style={{
                backgroundImage: `url(${
                  service === "Online coaching" ? logo : logo2
                })`,
              }}
            >
              <div className="service-info">
                <h1>{service}</h1>
                <ul className="service-description">
                  {service === "Online coaching" ? (
                    <>
                      <li className="additional-info">
                        De online coaching biedt gepersonaliseerde begeleiding
                        voor techniek, training, en voeding. Door middel van een
                        uitgebreide vragenlijst en goede communicatie gaan we
                        samen kijken wat het beste past bij jouw leefstijl. We
                        zullen het zo samenstellen dat we samen voor de lange
                        termijn veranderingen gaan! Ik kan je uitvoering
                        analyseren d.m.v. video’s, meekijken met je
                        voedingsdagboek, en je trainingen aanpassen naar jouw
                        wensen. Met ondersteuning van de op maat gemaakte
                        plannen gaan we samen voor duurzame veranderingen.
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="additional-info">
                        Bij 1-op-1 training sta jij met je training centraal. De
                        focus ligt op blessurevrije trainingen en zelfverzekerd
                        worden in de uitvoering, waarbij elke beweging is
                        afgestemd op jouw lichaam. Ik leg je dan ook graag de
                        waarom vraag uit wat betreft de bewegingen. Door
                        persoonlijke begeleiding kunnen we samen de optimale
                        bewegingen ontdekken die passen bij jouw fysieke
                        capaciteiten. Dit zorgt voor een veilige en effectieve
                        trainingen, waardoor je met vertrouwen aan je
                        fitnessdoelen kunt werken.
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Service;
